import React, { useState, useEffect } from "react";
import styles from "./autoPlayTabs.module.scss";
import { autoPlayTabs } from "@/lib/homePage";
import Image from "next/image";

const AutoPlayTabsWithProgress = () => {
  // Tab data and configuration
  const switchInterval = 7000; // Switch every 5 seconds
  const [activeTab, setActiveTab] = useState(0);
  const [progress, setProgress] = useState(0);

  // Autoplay effect for switching tabs
  useEffect(() => {
    const progressInterval = 100; // Update the progress every 100ms
    let progressTime = 0;

    // Progress timer to fill the bar
    const progressTimer = setInterval(() => {
      progressTime += progressInterval;
      setProgress((progressTime / switchInterval) * 100);
      // debugger;

      if (progressTime >= switchInterval) {
        setActiveTab((prevTab) => (prevTab + 1) % autoPlayTabs.length);
        progressTime = 0;
        setProgress(0);
      }
    }, progressInterval);

    // Cleanup interval on component unmount or tab change
    return () => clearInterval(progressTimer);
  }, [activeTab, switchInterval, autoPlayTabs.length]);

  // Handle manual tab click and reset progress bar
  const handleTabClick = (index) => {
    setActiveTab(index);
    setProgress(0); // Reset progress on manual tab switch
  };

  return (
    <div className={`${styles["autoPlayTabs"]}`}>
      <div className={`${styles["tab-header"]}`}>
        {autoPlayTabs.map((tab, index) => (
          <>
            <button
              key={index}
              onClick={() => handleTabClick(index)}
              className={`${activeTab === index ? styles["active"] : ""}`}
            >
              {/* Progress Bar */}
              <div className={`${styles["progress-bar-container"]}`}>
                <div
                  className={`${styles["progress-bar"]}`}
                  style={{
                    width: activeTab === index ? `${progress}%` : `0%`,
                  }}
                />
              </div>

              <div
                className={`${styles["text"]} ${
                  activeTab === index ? styles["active"] : ""
                }`}
              >
                <h2 className={`${styles["tabTitle"]}`}>
                  <div dangerouslySetInnerHTML={{ __html: tab.icon }} />
                  <span>{tab.title}</span>
                </h2>
                <p className={`${styles["tabDesc"]}`}>{tab.description}</p>
              </div>
            </button>
          </>
        ))}
      </div>
      {/* Tab content */}
      <div className={`${styles["tabContentContainer"]}`}>
        {autoPlayTabs.map((tab, index) => (
          <div
            key={index}
            className={`${styles["tabContent"]} ${
              activeTab === index ? styles["show"] : ""
            }`}
          >
            <Image
              src={tab.image}
              width={640}
              height={480}
              quality={100}
              alt=""
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AutoPlayTabsWithProgress;
