import { svgImages } from "@/lib/svgImages";
export const homeProducts = [
  {
    id: 0,
    name: "vCards",
    description: "Share contact details seamlessly.",
    icon: `${svgImages.vCardHomeProducts}`,
    url: "/en/products/qr-vcard/",
    json: "/images/home/homeProductsSection/vCard.json",
  },
  {
    id: 1,
    name: "Landing Pages",
    description: "Drive traffic to custom landing pages.",
    icon: `${svgImages.landingPagesHomeProducts}`,
    url: "/en/products/qr-landing-page/",
    json: "/images/home/homeProductsSection/landingPages.json",
  },
  {
    id: 2,
    name: "Gallery",
    description: "Showcase images in a QR gallery.",
    icon: `${svgImages.galleryHomeProducts}`,
    url: "/en/products/qr-gallery/",
    json: "/images/home/homeProductsSection/gallery.json",
  },
  {
    id: 3,
    name: "Files",
    description: "Link to documents quickly.",
    icon: `${svgImages.filesHomeProducts}`,
    url: "/en/products/qr-documents/",
    json: "/images/home/homeProductsSection/files.json",
  },
  {
    id: 4,
    name: "Videos",
    description: "Showcase images in a QR gallery.",
    icon: `${svgImages.videosHomeProducts}`,
    url: "/en/products/qr-videos/",
    json: "/images/home/homeProductsSection/videos.json",
  },
  {
    id: 5,
    name: "Links",
    description: "Direct to any URL.",
    icon: `${svgImages.linksHomeProducts}`,
    url: "/en/products/qr-app-links/",
    json: "/images/home/homeProductsSection/links.json",
  },
  {
    id: 6,
    name: "List of Links",
    description: "Organize multiple links in one QR code.",
    icon: `${svgImages.listOfLinksHomeProducts}`,
    url: "/en/products/qr-list-of-links/",
    json: "/images/home/homeProductsSection/listOfLinks.json",
  },

  {
    id: 7,
    name: "Business Profile",
    description: "Share comprehensive business information.",
    icon: `${svgImages.businessProfileHomeProducts}`,
    url: "/en/products/qr-business/",
    json: "/images/home/homeProductsSection/businessProfile.json",
  },
  {
    id: 8,
    name: "Wi-Fi",
    description: "Share wifi access",
    icon: `${svgImages.wifiHomeProducts}`,
    url: "/en/products/qr-wi-fi",
    json: "/images/home/homeProductsSection/wifi.json",
  },
];
export const whyChooseQrhub = [
  {
    id: 0,
    title: "Dynamic<br />Content Updates",
    description:
      "Change the destination of your QR codes anytime without needing to print new ones.",
    url: "",
    icon: svgImages.dynamic_content_updates,
  },
  {
    id: 1,
    title: "Customizable<br />Designs",
    description:
      "Tailor QR codes to match your brand’s identity with customizable colors, logos, and styles.",
    url: "",
    icon: svgImages.customizableDesign,
  },
  {
    id: 2,
    title: "Advanced<br />Analytics",
    description:
      "Track scans and user interactions in real time. Gain insights into customer behavior and engagement with detailed reports.",
    url: "",
    icon: svgImages.advancedAnalytics,
  },
  {
    id: 3,
    title: "Domain<br />White Labeling",
    description:
      "Use your own domain to create QR codes, maintaining brand consistency and trust.",
    url: "",
    icon: svgImages.domainWhiteLabeling,
  },
  {
    id: 4,
    title: "Secure<br />& Reliable",
    description:
      "Focus on security by saying, “Keep your QR codes protected with top-notch security features ensuring reliable performance,” to clearly differentiate this feature from the others.",
    url: "",
    icon: svgImages.secureReliable,
  },
];
export const autoPlayTabs = [
  {
    title: "Create",
    description:
      "Get started by generating your dynamic QR code in just a few clicks. Choose from various QR code types to suit your needs, whether it's for a website, file, or contact details. Our platform makes the creation process seamless and intuitive.",
    icon: svgImages.createIcon,
    image: "/images/home/create.webp",
  },
  {
    title: "Customize",
    description:
      "Make your QR code truly yours! Personalize it with unique designs, colors, and even add your brand logo. You can also configure advanced options like password protection, scan limits, and scheduling for added control.",
    icon: svgImages.customizeIcon,
    image: "/images/home/customize.webp",
  },
  {
    title: "Publish",
    description:
      "Once your QR code is ready, it's time to go live! Download, share, or embed your QR code anywhere you need it. Track performance with real-time analytics, ensuring your campaign's success is always in view.",
    icon: svgImages.publishIcon,
    image: "/images/home/publish.webp",
  },
];
