import TitleWithArrow from "@/components/titleWithArrow/titleWithArrow";
// import WhyChooseQrhub from "@/components/whyChooseQrhub/whyChooseQrhub";
// import DynamicSection from "../../components/dynamicSection/dynamicSection";
// import SignUpSection from "../../components/signUpSection/signUpSection";
import styles from "./home.module.scss";

import { useTranslation } from "react-i18next";
// import ReviewsSection from "@/components/reviewsSection/reviewsSection";
import HeroGlobal from "@/components/heroGlobal/heroGlobal";
// import Script from "next/script";
import ProductsTabsSection from "@/components/productsTabsSection/productsTabsSection";
import AutoPlayTabs from "@/components/autoPlayTabs/autoPlayTabs";
// import Spline from "@splinetool/react-spline";
import { useEffect, useRef, useState } from "react";
import Head from "next/head";
// import Image from "next/image";

// import BoxReveal from "@/components/ui/box-reveal";

// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

// import { productsData, getProductsData } from "@/helpers/main.js";
import CustomSpinner from "@/components/spinner/spinner";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import dynamic from "next/dynamic";
import Image from "next/image";

// const ProductsTabsSection = dynamic(
//   () => import("@/components/productsTabsSection/productsTabsSection"),
//   {
//     ssr: false,
//     loading: () => <CustomSpinner />,
//   }
// );

const WhyChooseQrhub = dynamic(
  () => import("@/components/whyChooseQrhub/whyChooseQrhub"),
  {
    ssr: false,
    loading: () => <CustomSpinner />,
  }
);

const Home = () => {
  const { t } = useTranslation();
  const [currentUrl, setCurrentUrl] = useState("");
  const [domainUrl, setDomainUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [heroImage, setHeroImage] = useState();

  useEffect(() => {
    if (window.location.href) {
      setCurrentUrl(window.location.href);
      setDomainUrl(window.location.hostname);
    }
  }, []);

  useEffect(() => {
    isDesktop
      ? setHeroImage(
          <Image
            src={"/images/home/bg-home.webp"}
            height={1017}
            width={945}
            priority={true}
            alt=""
          />
        )
      : setHeroImage(
          <Image
            src={"/images/home/bg-home-mobile.webp"}
            height={412}
            width={458}
            priority={true}
            alt=""
          />
        );
  }, [isDesktop]);

  return (
    <>
      {loading ? (
        <>
          <CustomSpinner />
        </>
      ) : (
        <>
          <Head>
            <title>QRHUB Solutions</title>
            <meta
              name="description"
              content="Transform your business with our dynamic QR code generator"
            ></meta>
            <meta property="og:title" content="QRHUB Solutions"></meta>
            <meta
              property="og:description"
              content="Transform your business with our dynamic QR code generator"
            ></meta>
            <meta
              property="og:image"
              content={`${domainUrl}/images/featured_images/home.jpg`}
            ></meta>
            <meta property="og:url" content={currentUrl}></meta>
          </Head>
          <div className={`${styles["home"]}`}>
            <HeroGlobal
              showIcon={true}
              icon={`/images/home/hero-icon.svg`}
              staticTitle=""
              title={`
              <div class="custom">Fast. <br />Reliable. <br />Customizable.</div>QR Code Solutions`}
              description={`Empower your business with dynamic QR codes, tailored digital experiences, and advanced tracking tools.`}
              buttonTitle={`Get Started`}
              buttonUrl={`/`}
              light={true}
              isHome={true}
              heroImage={
                <>
                  <div className={`${styles["heroImage"]}`}>{heroImage}</div>
                </>
              }
            />

            <div className={`${styles["productsContainer"]} container`}>
              <div className="inner">
                <TitleWithArrow
                  title="Simplify Your QR Code Experience"
                  description="Design, personalize, and share dynamic QR codes in minutes."
                  withArrow={false}
                />
                <AutoPlayTabs />
                <TitleWithArrow
                  title="Our Products"
                  description="No technical expertise required our step-by-step guide ensures anyone can generate and use QR codes effectively."
                />
                <div className={`${styles["productsTabs"]}`}>
                  <ProductsTabsSection />
                </div>

                <TitleWithArrow
                  title="Why Choose QRHUB"
                  description="No technical expertise required our step-by-step guide ensures anyone can generate and use QR codes effectively."
                />
                <div className={`${styles["whyChooseQrhubContainer"]}`}>
                  <WhyChooseQrhub />
                </div>
                {/* <ReviewsSection /> */}
                {/* <div className={`${styles["splineConnect"]}`}>
                  <Spline scene="https://prod.spline.design/Byi4stCIKuvJywEI/scene.splinecode" />
                </div> */}
              </div>
            </div>

            {/* <SignUpSection
        title={t("homePage.signUpSection.title")}
        subTitle={t("homePage.signUpSection.subTitle")}
        buttonTitle={t("homePage.signUpSection.buttonTitle")}
        buttonUrl={t("homePage.signUpSection.buttonUrl")}
        leftPhone={t("homePage.signUpSection.leftPhone")}
        rightPhone={t("homePage.signUpSection.rightPhone")}
      /> */}
          </div>
        </>
      )}
    </>
  );
};

export default Home;
